import { useEffect } from "react";

import SectionBannerPromotion from "./SectionBannerPromotion";
import SectionCTA from "./SectionCTA";
import LoadingDots from "../atoms/LoadingDots";

import SectionFeaturedLaunches from "~/components/sections/SectionFeaturedLaunches";
import SectionGallerySix from "~/components/sections/SectionGallerySix";
import SectionGalleryThree from "~/components/sections/SectionGalleryThree";
import SectionHTMLContent from "~/components/sections/SectionHTMLContent";
import SectionProjectLeftWithGallery from "~/components/sections/SectionProjectLeftWithGallery";
import SectionProjectTopWithGallery from "~/components/sections/SectionProjectTopWithGallery";
import SectionProjectWithScrollGallery from "~/components/sections/SectionProjectWithScrollGallery";
import SectionSlideCards from "~/components/sections/SectionSlideCards";
import SectionSwiftCards from "~/components/sections/SectionSwiftCards";
import { ISection } from "~/entities/section";
import { useIntersectionObserver } from "~/hooks/useIntersectionObserver";
import { ELayout } from "~/utilities/enums/Layouts";
import { Slug } from "~/utilities/enums/Slug";

// Map API sections to section components
const sectionComponents: Record<ELayout, any> = {
  SectionProjectLeftWithGallery,
  SectionGalleryThree,
  SectionProjectTopWithGallery,
  SectionProjectWithScrollGallery,
  SectionSwiftCards,
  SectionSlideCards,
  SectionGallerySix,
  SectionFeaturedLaunches,
  SectionHTMLContent,
  SectionCTA,
  SectionPromotionBanner: SectionBannerPromotion,
};

// Display a section individually
export const Section = ({ sectionData }: { sectionData: ISection }) => {
  // Prepare the component
  const SectionComponent = sectionComponents?.[sectionData?.layout];

  if (!SectionComponent) {
    return <></>;
  }
  // Display the section
  return (
    <SectionComponent
      data-name={sectionData?.layout || ""}
      // NOTE: handle view all homepage by location
      data={{
        ...sectionData,
        slug: `${Slug.PROJECTS}?location=${sectionData?.projects?.at(0)
          ?.location?.slug}`,
      }}
      slug={`${Slug.PROJECTS}?location=${sectionData?.projects?.at(0)?.location
        ?.slug}`}
    />
  );
};

interface SectionsProps {
  sections: ISection[];
  infinitiLoad?: boolean;
  isLoading?: boolean;
  onLoadMore?: () => void;
}

// Display the list of sections
const Sections: React.FC<SectionsProps> = ({
  sections,
  infinitiLoad = false,
  onLoadMore,
  isLoading = false,
}) => {
  const { ref, isIntersecting } = useIntersectionObserver({
    threshold: 0.5,
  });

  useEffect(() => {
    if (isLoading) return;
    if (infinitiLoad && isIntersecting && onLoadMore) {
      onLoadMore();
    }
  }, [isIntersecting, onLoadMore, isLoading]);

  return (
    <div className="flex flex-col">
      {sections?.map((section, index) => {
        return (
          <Section sectionData={section} key={`${section?.id}-${index}`} />
        );
      })}
      {isLoading ? <LoadingDots /> : <div className="" ref={ref} />}
    </div>
  );
};

export default Sections;
